import 'velocity-animate';

function installOxemNonce() {
  $('input[name="oxem_nonce"]').each((_, e) => {
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      method: 'get',
      data: {
        action: 'get_oxem_nonce',
      },
      success(data) {
        $(e).val(data);
      },
    });
  });
}

installOxemNonce();

const validate = (field, type) => {
  let pp = '';

  if (type === 'email') {
    pp = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  if (type === 'onlyNumber') {
    pp = /^([0-9()\\/+ -]+)$/;
  }

  if (type === 'phone') {
    pp = /^\+?[\d()\-\s]*\d+\s*$/;
  }

  if (type === 'notEmpty') {
    pp = /^[A-Za-zА-Яа-я0-9 _+]*[A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9 _#()$@8%№'":,.+?!#*-/\n]*$/;
  }

  if (!field.match(pp)) {
    return false;
  }
  return true;
};

let required = 0;
let validated = 0;

$('[data-validate]').on('change', function () {
  const fieldType = $(this).data('validate');
  if (!validate($(this).val(), fieldType)) {
    $(this)
      .parent()
      .removeClass('has-success')
      .addClass('has-error');
  } else {
    $(this)
      .parent()
      .removeClass('has-error')
      .addClass('has-success');
  }

  required = $(this)
    .parents('form')
    .find('.required').length;
  validated = $(this)
    .parents('form')
    .find('.has-success').length;

  if (required === validated) {
    $(this)
      .parents('form')
      .find('.btn[type="submit"]')
      .addClass('is-success');
  } else {
    $(this)
      .parents('form')
      .find('.btn[type="submit"]')
      .removeClass('is-success');
  }
});

$('body').on('submit', 'form', function (event) {
  event.preventDefault();
  const $form = $(this);
  const $submitButton = $form.find('.btn[type=submit]');

  $form.find('[data-validate]').each(function () {
    const fieldType = $(this).data('validate');
    if (!validate($(this).val(), fieldType)) {
      $(this)
        .parent()
        .removeClass('has-success')
        .addClass('has-error');
    } else {
      $(this)
        .parent()
        .removeClass('has-error')
        .addClass('has-success');
    }
  });
  required = $form.find('.required').length;
  validated = $form.find('.has-success').length;

  if (required === validated) {
    const postUrl = $form.attr('action');
    const data = $form.serialize();
    $form.find('.btn[type="submit"]').addClass('is-success');

    $submitButton.addClass('is-loading');
    $submitButton.prop('disabled', true);
    $submitButton.append(
      '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
    );

    $.ajax({
      type: 'POST',
      url: postUrl,
      data,
      success(response) {
        installOxemNonce();
        setTimeout(() => {
          $submitButton.removeClass('is-loading');
          $form.find('.lds-ring').remove();
          $form.addClass('is-success');
          $form.find('.btn[type="submit"]').removeClass('is-success');

          if ($form.hasClass('subscribe-form')) {
            $form
              .find('.subscribe-form__success')
              .velocity('stop')
              .velocity('fadeIn', { duration: 0, queue: false });
            setTimeout(() => {
              $form.find('.subscribe-form__success').addClass('is-open');
            }, 10);

            setTimeout(() => {
              $submitButton.removeAttr('disabled');
              $form.find('.subscribe-form__success').removeClass('is-open');
              setTimeout(() => {
                $form
                  .find('.subscribe-form__success')
                  .velocity('stop')
                  .velocity('fadeOut', { duration: 400, queue: false });
              }, 800);
            }, 4000);
          }

          if ($form.find('.form-success').length) {
            $form.find('.form__body').hide();
            $form
              .find('.form-success')
              .velocity('stop')
              .velocity('fadeIn', { duration: 400, queue: false });

            setTimeout(() => {
              $submitButton.removeAttr('disabled');

              if ($('.modal').is(':visible')) {
                $.fancybox.close();

                setTimeout(() => {
                  $form.trigger('reset');
                  $form.find('.has-success').removeClass('has-success');
                  $form.removeClass('is-success');
                  if ($form.find('.form-success').length) {
                    $form
                      .find('.form__body')
                      .velocity('stop')
                      .velocity('fadeIn', { duration: 400, queue: false });
                    $form.find('.form-success').hide();
                  }
                }, 500);
              } else if ($form.find('.form-success').length) {
                $form.trigger('reset');
                $form.find('.has-success').removeClass('has-success');
                $form.removeClass('is-success');
                $form
                  .find('.form__body')
                  .velocity('stop')
                  .velocity('fadeIn', { duration: 400, queue: false });
                $form.find('.form-success').hide();
              }
            }, 4000);
          } else {
            $submitButton.removeAttr('disabled');
            $form.trigger('reset');
            $form.find('.has-success').removeClass('has-success');
            $form.removeClass('is-success');
          }
        }, 1000);
        if ($form.hasClass('test-form')) {
          const jResponse = JSON.parse(response);
          if (jResponse.state !== 'error') {
            window.OxemTheme.zebraTest.receiveTestPage(jResponse.hash);
          }
        }
      },
      error() {
        setTimeout(() => {
          $submitButton.removeClass('is-loading');
          $form.find('.lds-ring').remove();
          $form.addClass('is-success');
          $form.find('.btn[type="submit"]').removeClass('is-success');

          if ($form.hasClass('subscribe-form')) {
            $form
              .find('.subscribe-form__success')
              .velocity('stop')
              .velocity('fadeIn', { duration: 0, queue: false });
            setTimeout(() => {
              $form.find('.subscribe-form__success').addClass('is-open');
            }, 10);

            setTimeout(() => {
              $submitButton.removeAttr('disabled');
              $form.find('.subscribe-form__success').removeClass('is-open');
              setTimeout(() => {
                $form
                  .find('.subscribe-form__success')
                  .velocity('stop')
                  .velocity('fadeOut', { duration: 400, queue: false });
              }, 800);
            }, 4000);
          }

          if ($form.find('.form-success').length) {
            $form.find('.form__body').hide();
            $form
              .find('.form-success')
              .velocity('stop')
              .velocity('fadeIn', { duration: 400, queue: false });

            setTimeout(() => {
              $submitButton.removeAttr('disabled');

              if ($('.modal').is(':visible')) {
                $.fancybox.close();

                setTimeout(() => {
                  $form.trigger('reset');
                  $form.find('.has-success').removeClass('has-success');
                  $form.removeClass('is-success');
                  if ($form.find('.form-success').length) {
                    $form
                      .find('.form__body')
                      .velocity('stop')
                      .velocity('fadeIn', { duration: 400, queue: false });
                    $form.find('.form-success').hide();
                  }
                }, 500);
              } else if ($form.find('.form-success').length) {
                $form.trigger('reset');
                $form.find('.has-success').removeClass('has-success');
                $form.removeClass('is-success');
                $form
                  .find('.form__body')
                  .velocity('stop')
                  .velocity('fadeIn', { duration: 400, queue: false });
                $form.find('.form-success').hide();
              }
            }, 4000);
          } else {
            $submitButton.removeAttr('disabled');
            $form.trigger('reset');
            $form.find('.has-success').removeClass('has-success');
            $form.removeClass('is-success');
          }
        }, 1000);
      },
    });
  } else {
    $form.find('.btn[type="submit"]').removeClass('is-success');
  }

  return false;
});

export default validate;
