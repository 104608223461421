import Rellax from 'rellax/rellax';
import isInPage from './utility/isInPage';

if (isInPage($('.b-subscribe__parallax')[0])) {
  const rellax = new Rellax('.b-subscribe__parallax', {
    speed: -4,
    center: true,
    round: false,
    vertical: true,
  });
}

if (isInPage($('.b-help__parallax')[0])) {
  const rellax = new Rellax('.b-help__parallax', {
    speed: -4,
    center: true,
    round: false,
    vertical: true,
  });
}
