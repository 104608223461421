import twigTools from './utility/twigTools';

let page = 2;

$('.btn--loading').on('click', (e) => {
  e.preventDefault();
  const $target = $(e.currentTarget);
  if (!$target.hasClass('is-loading')) {
    $target.addClass('is-loading');
    const postType = $target.data('post-type');
    const template = $target.data('template');
    const appendSelector = $target.data('append-selector');
    const categories = $target.data('category') === undefined ? 0 : $target.data('category');
    const fetchData = function () {
      $.ajax({
        url: `/wp-json/wp/v2/${postType}`,
        method: 'get',
        data: {
          _embed: true,
          page,
          per_page: window.OxemTheme.postsPerPage[postType],
          categories,
          'filter[orderby]': 'date',
          order: 'desc',
        },
        success(data) {
          const posts = [];
          data.forEach((val) => {
            const post = {
              link: val.link,
              title: val.title.rendered,
              content: val.content.rendered.replace(/<\/?[^>]+>/gi, ''), // strip tags
            };
            Object.keys(val['acf']).forEach((key) => {
              post[key] = val['acf'][key];
            });
            const mediaDetails = (((val['_embedded'] || {})['wp:featuredmedia'] || {})[0] || {})['media_details']; // check for nested field
            if (mediaDetails !== undefined) {
              post.thumbnail = {
                src(size) {
                  return mediaDetails.sizes[size]['source_url'];
                },
              };
            }
            posts.push(post);
          });
          const output = $(twigTools.render({
            posts,
            theme: {
              path: window.OxemTheme['themePath'],
            },
          }, template));
          if ($target.data('use-packery')) {
            $(appendSelector)
              .packery()
              .append(output)
              .packery('appended', output)
              .packery('layout');
          } else {
            $(appendSelector).append(output);
          }
          page += 1;
          if (data.length < window.OxemTheme.postsPerPage[postType]) {
            $('.load-more').remove();
          } else {
            $target.removeClass('is-loading');
          }
        },
        error(data) {
          if (data.status === 400) {
            $('.load-more').remove();
          } else {
            $target.removeClass('is-loading');
          }
        },
      });
    };
    twigTools.loadTemplate(`${window.OxemTheme['themePath']}/templates/${template}.twig`, fetchData, template);
  }
});
