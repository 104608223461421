import '@fancyapps/fancybox';
import 'inputmask/dist/jquery.inputmask.bundle';
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const BodyScrollOptions = {
  reserveScrollBarGap: true,
};

$('[data-fancybox]').fancybox({
  hideScrollbar: false,
  hash: false,
  animationEffect: 'fade',
  buttons: ['close'],
  btnTpl: {
    smallBtn:
      `<button data-fancybox-close class="fancybox-close-small" title="Закрыть"><img src="${window.OxemTheme.themePath}/static/dist/img/close.svg" alt=""/><img src="${window.OxemTheme.themePath}/static/dist/img/close2.svg" alt=""/></button>`,
  },
  touch: false,
  beforeShow(instance, current) {
    const targetElement = document.querySelector('.fancybox-slide');
    disableBodyScroll(targetElement, BodyScrollOptions);

    $(window).resize();

    $('.modal input.phone-mask').inputmask({
      mask: '+7 (999) 999-99-99',
      showMaskOnHover: false,
    });
  },
  beforeClose() {
    const targetElement = document.querySelector('.fancybox-slide');
    enableBodyScroll(targetElement, BodyScrollOptions);
    clearAllBodyScrollLocks();
  },
  afterClose() {
    clearAllBodyScrollLocks();
  },
});
