import 'match-media';
import 'match-media/matchMedia.addListener';

const mql = window.matchMedia('only screen and (max-width: 734px)');
let fixPoint;

function handleMQL() {
  if (mql.matches) {
    fixPoint = 0;
  } else {
    fixPoint = 22;
  }
}
handleMQL();

mql.addListener(() => {
  handleMQL();
});

const headerFixed = () => {
  const scrollTop = $(window).scrollTop();

  if (scrollTop > fixPoint) {
    $('.header').addClass('is-fixed');
  } else {
    $('.header').removeClass('is-fixed');
  }
};

headerFixed();

$(window).on('scroll', () => {
  headerFixed();
});
