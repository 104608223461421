import 'velocity-animate';
import inView from 'in-view';

inView('.service-preview__icon').on('enter', (el) => {
  $(el)
    .find('path')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('circle')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('ellipse')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('polyline')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('line')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
});


inView('.benefit').on('enter', (el) => {
  $(el)
    .find('path')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('circle')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('ellipse')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('polyline')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
  $(el)
    .find('line')
    .velocity({ 'stroke-dashoffset': 0 }, { duration: 3000 });
});
