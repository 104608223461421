(function () {
  $.fn.transformHeroes = function () {
    // Variables
    const perspective = '500px';

    const delta = 30;

    const width = this.width();

    const height = this.height();

    const midWidth = width / 2;

    const midHeight = height / 2;
    // Events
    this.on({
      mousemove(e) {
        const pos = $(this)
          .find('.client__text-parallax')
          .offset();

        const cursPosX = e.pageX - pos.left;

        const cursPosY = e.pageY - pos.top;

        const cursCenterX = midWidth - cursPosX;

        const cursCenterY = midHeight - cursPosY;

        $(this)
          .find('.client__text-parallax')
          .css(
            'transform',
            `perspective(${perspective}) rotateX(${cursCenterY / delta}deg) rotateY(${-(
              cursCenterX / delta
            )}deg)`,
          );
        $(this)
          .find('.client__text-parallax')
          .removeClass('is-out');
      },
      mouseleave() {
        $(this)
          .find('.client__text-parallax')
          .addClass('is-out');
      },
    });
    // Return
    return this;
  };
}(jQuery));

$('.client').transformHeroes();

$('.client__expand-link a').on('click', function (e) {
  e.preventDefault();

  $(this).toggleClass('is-active');

  if ($(this).hasClass('is-active')) {
    $(this)
      .parents('.client')
      .find('.client__descr')
      .velocity('stop')
      .velocity('slideDown', { duration: 400, queue: false });
  } else {
    $(this)
      .parents('.client')
      .find('.client__descr')
      .velocity('stop')
      .velocity('slideUp', { duration: 400, queue: false });
  }
});
