import Typed from 'typed.js';
import isInPage from './utility/isInPage';
import 'velocity-animate';
import 'match-media';
import 'match-media/matchMedia.addListener';

const mql = window.matchMedia('only screen and (max-width: 734px)');
let timer;
const duration = 8000;
const fadeDuration = 400;
let currentActiveSlide = 0;
let isMobile = false;
const mobileTexts = [
  'Работаю <br>с самым важным –<br> <span>именем <br>и репутацией</span>',
  'Основатель <br>и директор<br> PR-агентства <br><span>Zebra Company</span>',
  '<span>Продвижение в Яндексе</span> <br>«Персональный <br>бренд: создание<br> и продвижение»',
  'Веду онлайн-<br>курсы и <span>тренинги<br></span> по личному <br>брендингу',
];
const desktopTexts = [
  'Работаю с самым важным –<br> <span>именем и репутацией</span>',
  'Основатель и директор<br> PR-агентства <span>Zebra <br>Company</span>',
  '<span>Продвижение в Яндексе</span> <br>«Персональный бренд: <br>создание и продвижение»',
  'Веду онлайн-курсы <br>и <span>тренинги</span> по личному <br>брендингу',
];

function runTimer() {
  const setTimer = function () {
    clearInterval(timer);
    timer = setInterval(() => {
      $('.hero-slider__control--next').trigger('click');
    }, duration);
  };
  if (document.readyState === 'complete') {
    setTimer();
  } else {
    $(window).on('load', setTimer);
  }
}

function typeText(index, mobile) {
  const $heroSlideText = $(`.hero-slide:nth-child(${index + 1}) .hero-slide__text`);
  $heroSlideText.find('h2').remove();
  $heroSlideText.append('<h2></h2>');
  const $typeEl = $heroSlideText.find('h2')[0];
  let texts;

  if (mobile) {
    texts = mobileTexts;
  } else {
    texts = desktopTexts;
  }

  $heroSlideText.siblings('.btn').hide();

  const type = new Typed($typeEl, {
    strings: [texts[index]],
    typeSpeed: 6,
    backSpeed: 0,
    loop: false,
    fadeOut: false,
    showCursor: false,
    onComplete: (self) => {
      $heroSlideText
        .siblings('.btn')
        .velocity('stop')
        .velocity('fadeIn', { duration: fadeDuration, queue: false });
    },
  });
}

function showSlide(index, mobile) {
  runTimer();
  $('.hero-slider__tab.is-active').removeClass('is-active');

  $('.hero-slider__tab')
    .eq(index)
    .addClass('is-active');

  $('.hero-slider__tab')
    .eq(index)
    .find('.hero-slider__tab-filler')
    .css({
      width: 0,
    });

  const setAnimation = function () {
    $('.hero-slider__tab')
      .eq(index)
      .find('.hero-slider__tab-filler')
      .velocity('stop')
      .velocity(
        {
          width: '100%',
        },
        {
          duration,
          easing: 'linear',
          queue: false,
        },
      );
  };
  if (document.readyState === 'complete') {
    setAnimation();
  } else {
    $(window).on('load', setAnimation);
  }

  $('.hero-slide')
    .eq(currentActiveSlide)
    .velocity('stop')
    .velocity('fadeOut', {
      duration: fadeDuration,
      queue: false,
      complete() {},
    });

  currentActiveSlide = index;

  if (mobile) {
    typeText(index, true);
  } else {
    typeText(index, false);
  }

  $('.hero-slide')
    .eq(index)
    .velocity('stop')
    .velocity('fadeIn', {
      duration: fadeDuration,
      queue: false,
    });
}

// prod
function handleMQL() {
  if (mql.matches) {
    isMobile = true;
    showSlide(0, true);
  } else {
    isMobile = false;
    showSlide(0, false);
  }
}

// dev
// function handleMQL() {
//   if (mql.matches) {
//     isMobile = true;
//     typeText(0, true);
//   } else {
//     isMobile = false;
//     typeText(0, false);
//   }
// }

if (isInPage($('.hero')[0])) {
  handleMQL();
  mql.addListener(() => {
    handleMQL();
  });

  $('.hero-slider__control--prev').on('click', () => {
    if (currentActiveSlide > 0) {
      let prevIndex = currentActiveSlide;
      prevIndex -= 1;
      if (isMobile) {
        showSlide(prevIndex, true);
      } else {
        showSlide(prevIndex, false);
      }
    } else if (isMobile) {
      showSlide(mobileTexts.length - 1, true);
    } else {
      showSlide(mobileTexts.length - 1, false);
    }
  });

  $('.hero-slider__control--next').on('click', () => {
    if (currentActiveSlide < mobileTexts.length - 1) {
      let prevIndex = currentActiveSlide;
      prevIndex += 1;
      if (isMobile) {
        showSlide(prevIndex, true);
      } else {
        showSlide(prevIndex, false);
      }
    } else if (isMobile) {
      showSlide(0, true);
    } else {
      showSlide(0, false);
    }
  });

  $('.hero-slider__tab').on('click', function () {
    const index = $(this).index();
    if (isMobile) {
      showSlide(index, true);
    } else {
      showSlide(index, false);
    }
  });

  $('.hero__scroll-down').on('click', (e) => {
    e.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $('.hero').outerHeight() - $('.header-fixed').outerHeight(),
      },
      500,
    );
  });
}
