import 'match-media';
import 'match-media/matchMedia.addListener';
import Swiper from 'swiper/dist/js/swiper.min';
import 'velocity-animate';

// real cases slider
const realCasesSlider = new Swiper('.real-cases-slider', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 10,
  effect: 'fade',
  parallax: true,
  speed: 600,
  fadeEffect: {
    crossFade: true,
  },
  navigation: {
    nextEl: '.real-cases-slider .swiper-button-next',
    prevEl: '.real-cases-slider .swiper-button-prev',
  },
  pagination: {
    el: '.real-cases-slider .swiper-pagination',
    clickable: true,
  },
  autoHeight: true,
});

const mql = window.matchMedia('only screen and (max-width: 734px)');

function handleMQL() {
  if (mql.matches) {
    $('.real-case').each(function () {
      $(this)
        .find('.real-case__task')
        .insertAfter($(this).find('.real-case__tabs'));
    });
  } else {
    $('.real-case').each(function () {
      $(this)
        .find('.real-case__task')
        .appendTo($(this).find('.real-case__top-col-right'));
    });
  }
}

handleMQL();
mql.addListener(() => {
  handleMQL();
});

$('.real-case__tabs a').on('click', function (e) {
  e.preventDefault();
  const $container = $(this).parents('.real-case');
  $container.find('.real-case__tabs a').removeClass('is-active');
  $(this).addClass('is-active');

  const tab = $(this).data('case-tab');

  $container.find('.real-case__bottom').css({
    'min-height': $container.find('.real-case__bottom').outerHeight(),
  });

  const velocityOptions = {
    duration: 400,
    queue: false,
    begin() {
      setTimeout(() => {
        $container.find('.real-case__bottom').css({
          'min-height': 'auto',
        });
      }, 10);
    },
  };

  const topHeight = parseInt($container.find('.real-case__top').height(), 10) + 310;
  console.log(topHeight);
  const $wrapper = $('.swiper-wrapper');
  if (tab === 'task') {
    $container.find('.real-case__services').hide();
    $container.find('.real-case__result').hide();
    $container
      .find('.real-case__task')
      .velocity('stop')
      .velocity('fadeIn', velocityOptions);
    $wrapper.height(parseInt($container.find('.real-case__task').height(), 10) + topHeight);
  }
  if (tab === 'services') {
    $container.find('.real-case__task').hide();
    $container.find('.real-case__result').hide();
    $container
      .find('.real-case__services')
      .velocity('stop')
      .velocity('fadeIn', velocityOptions);
    $wrapper.height(parseInt($container.find('.real-case__services').height(), 10) + topHeight);
  }
  if (tab === 'result') {
    $container.find('.real-case__services').hide();
    $container.find('.real-case__task').hide();
    $container
      .find('.real-case__result')
      .velocity('stop')
      .velocity('fadeIn', velocityOptions);
    $wrapper.height(parseInt($container.find('.real-case__result').height(), 10) + topHeight);
  }
});

// reviews slider
let isSmallResolution = false;
let reviewsSlider;

function handleReviewsSliderSlider() {
  if ($('.reviews-slider').length) {
    if (window.innerWidth < 735 && !isSmallResolution) {
      isSmallResolution = true;
      reviewsSlider = new Swiper('.reviews-slider', {
        loop: false,
        slidesPerView: 'auto',
        freeMode: true,
        threshold: 10,
        spaceBetween: 0,
      });
    }

    if (window.innerWidth > 734 && isSmallResolution) {
      isSmallResolution = false;
      reviewsSlider.destroy(false, true);
    }
  }
}
handleReviewsSliderSlider();

$(window).on('resize', handleReviewsSliderSlider);

// gallery
$('.gallery').each(function () {
  const fullImages = $(this).find('.gallery__large')[0];
  const thumbs = $(this).find('.gallery__thumbnails')[0];

  const galleryThumbs = new Swiper(thumbs, {
    loop: true,
    spaceBetween: 20,
    centeredSlides: false,
    threshold: 10,
    slidesPerView: 6,
    loopedSlides: 8,
    slideToClickedSlide: true,
    navigation: {
      nextEl: $(thumbs).siblings('.swiper-button-next'),
      prevEl: $(thumbs).siblings('.swiper-button-prev'),
    },
    breakpoints: {
      600: {
        slidesPerView: 4,
      },
      410: {
        slidesPerView: 3,
      },
    },
  });

  const realCasesSlider = new Swiper(fullImages, {
    loop: true,
    loopedSlides: 8,
    slidesPerView: 1,
    spaceBetween: 0,
    threshold: 10,
    speed: 600,
    navigation: {
      nextEl: $(fullImages).find('.swiper-button-next'),
      prevEl: $(fullImages).find('.swiper-button-prev'),
    },
    pagination: {
      el: $(fullImages).find('.swiper-pagination'),
      clickable: true,
    },
    on: {
      // slideChange() {
      //   const index = this.realIndex;
      //   console.log(index);
      //   $(thumbs)
      //     .find('.gallery__thumbnail')
      //     .removeClass('is-active');
      //   $(thumbs)
      //     .find(`.swiper-slide[data-swiper-slide-index="${index}"]`)
      //     .find('.gallery__thumbnail')
      //     .addClass('is-active');
      // },
    },
  });

  if (thumbs) {
    realCasesSlider.controller.control = galleryThumbs;
    galleryThumbs.controller.control = realCasesSlider;
  }
});
