import 'match-media';
import 'match-media/matchMedia.addListener';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const BodyScrollOptions = {
  reserveScrollBarGap: true,
};
const targetElement = document.querySelector('.mobile-menu');

const mqlTouch = window.matchMedia('only screen and (max-width: 934px)');
const mqlMobile = window.matchMedia('only screen and (max-width: 734px)');

function handleMQLTouch() {
  if (mqlTouch.matches) {
    $('.header-nav').prependTo('.mobile-menu');
  } else {
    $('.header-nav').prependTo('.header__right');
  }
}

function handleMQLMobile() {
  if (mqlMobile.matches) {
    $('.header__buttons').appendTo('.mobile-menu');
  } else {
    $('.header__buttons').insertBefore('.menu-toggle');
  }
}

handleMQLTouch();
mqlTouch.addListener(() => {
  handleMQLTouch();
});

handleMQLMobile();
mqlMobile.addListener(() => {
  handleMQLMobile();
});

let timer;
$('.menu-toggle').on('click', function () {
  clearInterval(timer);
  $(this).toggleClass('is-active');
  $('.mobile-menu').addClass('is-animate');
  $('.mobile-menu').toggleClass('is-active');
  if ($(this).hasClass('is-active')) {
    disableBodyScroll(targetElement, BodyScrollOptions);
  } else {
    clearAllBodyScrollLocks();
  }

  timer = setTimeout(() => {
    $('.mobile-menu').removeClass('is-animate');
  }, 900);
});
