$(document).ready(() => {
  if (window.OxemTheme['adjustCounters'] !== undefined) {
    const initCounterAdjustment = function () {
      const baseValues = {};
      Object.keys(window.OxemTheme['adjustCounters']).forEach((service) => {
        const $counter = $(`.ya-share2__item_service_${service} .ya-share2__counter`);
        $counter.parents('.ya-share2__link').on('click', () => {
          baseValues[service] += 1;
        });
        let observer;
        const observerParams = {
          attributes: false,
          childList: true,
          subtree: false,
        };
        const adjustValue = function () {
          const counterText = $counter.text();
          if (counterText !== '') {
            if (baseValues[service] === undefined) {
              baseValues[service] = parseInt($counter.text(), 10);
            }
            observer.disconnect();
            $counter.text(baseValues[service] + window.OxemTheme['adjustCounters'][service]);
            observer.observe($counter[0], observerParams);
          }
        };
        adjustValue();
        observer = new MutationObserver(adjustValue);
        observer.observe($counter[0], observerParams);
      });
    };
    const waitForCounters = function () {
      if ($(`.ya-share2__item_service_${Object.keys(window.OxemTheme['adjustCounters'])[0]} .ya-share2__counter`).length > 0) {
        initCounterAdjustment();
      } else {
        setTimeout(waitForCounters, 500);
      }
    };
    waitForCounters();
  }
});
