import 'jquery.dotdotdot/dist/jquery.dotdotdot';

$('.article-preview__descr p').dotdotdot({
  watch: true,
});

$('.review:not(.review--full) .review__text').each(function () {
  const API = $(this)
    .dotdotdot({
      callback(isTruncated) {
        if (isTruncated) {
          $(this)
            .siblings('.review__read-more')
            .show();
        } else {
          $(this)
            .siblings('.review__read-more')
            .hide();
        }
      },
      watch: true,
    })
    .data('dotdotdot');

  $(this)
    .siblings('.review__read-more')
    .find('a')
    .on('click', function (e) {
      e.preventDefault();
      const $text = $(this)
        .parent()
        .siblings('.review__text');

      $(this).toggleClass('is-active');

      if ($(this).hasClass('is-active')) {
        API.restore();
        $text.velocity(
          {
            height: parseInt($text.find('p').outerHeight(), 10) + 15,
            maxHeight: parseInt($text.find('p').outerHeight(), 10) + 15,
          },
          {
            duration: 400,
            queue: false,
          },
        );
      } else {
        $text.velocity(
          {
            height: 110,
            maxHeight: 110,
          },
          {
            duration: 400,
            queue: false,
            complete() {
              $text.css('height', 'auto');
              API.truncate();
            },
          },
        );
      }
    });
});
