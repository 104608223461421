import Twig from 'twig';

const twigTools = {
  templates: [],
  loadTemplate(path, callback, id) {
    const self = this;
    if (self.templates[id] === undefined) {
      $.ajax({
        url: path,
        beforeSend(x) { // fix for "xml not well-formed" in Firefox
          if (x && x.overrideMimeType) {
            x.overrideMimeType('text/plain;charset=UTF-8');
          }
        },
        success(data) {
          self.templates[id] = Twig.twig({
            id,
            data,
          });
          callback();
        },
      });
    } else {
      callback();
    }
  },
  render(params, id) {
    return this.templates[id].render(params);
  },
};

export default twigTools;
