import 'match-media';
import 'match-media/matchMedia.addListener';

const mql = window.matchMedia('only screen and (max-width:934px) and (min-width: 735px)');

function handleMQL() {
  if (mql.matches) {
    $('.footer__developer').insertAfter('.footer__copyright');
  } else {
    $('.footer__developer').appendTo('.footer__col:nth-child(4)');
  }
}

handleMQL();
mql.addListener(() => {
  handleMQL();
});
