import Packery from 'packery';

import jQueryBridget from 'jquery-bridget';

jQueryBridget('packery', Packery, $);

const $reviewsGrid = $('.reviews-grid');
if ($reviewsGrid.length) {
  $reviewsGrid.packery({
    itemSelector: '.review',
    gutter: 30,
    transitionDuration: 0,
  });
}
