console.log('      ___           ___            ___           ___     \n'
  + '     /\\  \\         /|  |          /\\__\\         /\\  \\    \n'
  + '    /::\\  \\       |:|  |         /:/ _/_       |::\\  \\   \n'
  + '   /:/\\:\\  \\      |:|  |        /:/ /\\__\\      |:|:\\  \\  \n'
  + '  /:/  \\:\\  \\   __|:|__|       /:/ /:/ _/_   __|:|\\:\\  \\ \n'
  + ' /:/__/ \\:\\__\\ /::::\\__\\_____ /:/_/:/ /\\__\\ /::::|_\\:\\__\\\n'
  + ' \\:\\  \\ /:/  / ~~~~\\::::/___/ \\:\\/:/ /:/  / \\:\\~~\\  \\/__/\n'
  + '  \\:\\  /:/  /      |:|~~|      \\::/_/:/  /   \\:\\  \\      \n'
  + '   \\:\\/:/  /       |:|  |       \\:\\/:/  /     \\:\\  \\     \n'
  + '    \\::/  /        |:|__|        \\::/  /       \\:\\__\\    \n'
  + '     \\/__/         |/__/          \\/__/         \\/__/    ');
console.log(`© ${(new Date()).getFullYear()} https://oxem.ru/`);
