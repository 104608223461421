window.OxemTheme.zebraTest = {
  receiveTestPage(hash, step = undefined) {
    const self = this;
    if (step === undefined) {
      window.location.hash = hash;
    } else {
      window.location.hash = `${hash},${step}`;
    }
    $.ajax({
      url: `${window.OxemTheme['themePath']}/include/test/test.php`,
      method: 'get',
      data: {
        testId: hash,
        step,
      },
      success(response) {
        $('#enterForm').remove();
        $('.article__body').html(response);
        const ldsRing = '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>';
        const $testNextButton = $('#questionsList .role-btn-next');
        const $testBackButton = $('#questionsList .role-btn-back');
        const $form = $('#questionsList');
        $([document.documentElement, document.body]).animate({
          scrollTop: $form.offset().top - 180,
        }, 500);
        $testNextButton.on('click', (e) => {
          e.stopImmediatePropagation();
          e.preventDefault();
          if (!$testNextButton.prop('disabled')) {
            const answersNum = 6;
            let answeredCounter = 0;
            $('#questionsList input[type=radio]:checked').each(() => {
              answeredCounter += 1;
            });
            if (answeredCounter < answersNum) {
              $('#questionsList .messageError').html(`Для перехода к следующим вопросам нужно ответить на все представленные вопросы.<br>Вы ответили на ${answeredCounter} из ${answersNum} вопросов на этой странице.`);
            } else {
              $testNextButton.addClass('is-success is-loading');
              $testNextButton.prop('disabled', true);
              $testNextButton.append(ldsRing);
              const request = $.ajax({
                url: `${window.OxemTheme['themePath']}/include/test/ajaxSaveAnswers.php`,
                method: 'post',
                data: $form.serialize(),
              });
              request.done((result) => {
                const r = $.parseJSON(result);
                if (r.state === 'error') {
                  $('#enterForm .messageError').text(r.error);
                } else if ($testNextButton.data('finish') === 1) {
                  const request2 = $.ajax({
                    url: `${window.OxemTheme['themePath']}/include/test/ajaxFinishTest.php`,
                    method: 'post',
                    data: {
                      hash,
                    },
                  });
                  request2.done((result2) => {
                    const rr = $.parseJSON(result2);
                    console.log(rr);
                    if (rr.state === 'success') {
                      $.fancybox.open('<div class="message"><h2>Благодарим за прохождение теста</h2><p> Проверьте вашу почту. Вам отправлено письмо с результатами.</p></div>');
                      $testNextButton.hide();
                    } else {
                      $.fancybox.open('<div class="message"><h2>Тест уже был завершен ранее. Проверьте вашу почту. Эту страницу можно закрыть.</h2>');
                      $testNextButton.hide();
                    }
                  });
                } else {
                  self.receiveTestPage(hash, $testNextButton.data('next'));
                }
              });
            }
          }
        });
        $testBackButton.on('click', (e) => {
          e.stopImmediatePropagation();
          e.preventDefault();
          if (!$testBackButton.prop('disabled')) {
            $testBackButton.addClass('is-success is-loading');
            $testBackButton.prop('disabled', true);
            $testBackButton.append(ldsRing);
            self.receiveTestPage(hash, $testBackButton.data('next'));
          }
        });
      },
    });
  },
};
